.tags-input {
	display: inline-block;
	padding: 0 2px;
	background: #FFF;
	border: 1px solid #CCC;
	width: 16em;
	border-radius: 2px;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);

	.tag {
		display: inline-block;
		background: #EEE;
		color: #444;
		padding: 0 4px;
		margin: 2px;
		border: 1px solid #CCC;
		border-radius: 2px;
		font: inherit;
		user-select: none;
		cursor: pointer;
		transition: all 100ms ease;

		&.selected {
			background-color: #777;
			border-color: #777;
			color: #EEE;
		}

		&.dupe {
			transform: scale3d(1.2,1.2,1.2);
			background-color: #FCC;
			border-color: #700;
		}
	}

	input {
		appearance: none !important;
		display: inline-block !important;
		padding: 3px;
		margin: 0 !important;
		background: none !important;
		border: none !important;
		box-shadow: none !important;
		font: inherit !important;
		font-size: 100% !important;
		outline: none !important;
	}

	.selected ~ input {
		opacity: 0.3;
	}
}
